<template>
  <div>
    <div class="back" @click="back"><i class="el-icon-arrow-left">返回</i></div>
    <!-- <div
      v-for="item in searchCriteria"
      :key="item.id"
      v-html="item.stepsContent"
    ></div> -->
    <div v-if="searchCriteria.length > 0">
      <div class="searContent" v-for="item in searchCriteria" :key="item.id">
        <h3 @click="products(item.id)" v-html="item.title"></h3>
        <!-- <p v-html="item.stepsContent"></p> -->
        <p
          @click="products(item.id)"
          v-if="itemstep == 1"
          v-html="item.stepsContent1"
        ></p>
        <p v-else v-html="item.stepsContent1"></p>
      </div>
    </div>
    <div v-if="searchCriteria.length == 0" class="fooder">
      <div><img src="../../assets/images/join_about/001.png" alt="" /></div>
      暂无搜索结果
    </div>
  </div>
</template>

<script>
import { getStepsContentByTitle } from "api/header.js";
export default {
  data() {
    return {
      searchCriteria: "458786121456",
      itemstep: 1,
    };
  },
  methods: {
    //   返回
    back() {
      this.$parent.input_value = "";
      this.$parent.showSearch = true;
    },
    // 查看更多
    products(id) {
      console.log(111);
      this.$parent.showSearchId = id;
      this.$parent.input_value = "";
      this.$parent.showSearch = true;
    },
    // 搜索
    getStepsContentByTitle() {
      getStepsContentByTitle(this.$parent.input_value).then((res) => {
        res.data.forEach((item) => {
          if (item.stepsContent) {
            if (item.stepsContent.length > 400) {
              console.log(item.stepsContent.length);
              this.itemstep = 1;
              item.stepsContent = item.stepsContent.slice(0, 400);
              item.stepsContent1 =
                item.stepsContent +
                "..." +
                " <span style='color:#ff6900;cursor: pointer;'>查看更多</span> ";
            } else {
              this.itemstep = 0;
              console.log(item.stepsContent);
              item.stepsContent1 = item.stepsContent;
            }
          }
        });
        this.searchCriteria = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.back {
  cursor: pointer;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-bottom: 39px;
}
.searContent {
  h3 {
    cursor: pointer;
    height: 18px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ff6900;
    margin: 39px 0 27px;
  }
  p {
    display: inline-block;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
    ::v-deep p {
      span {
        font-size: 16px !important;
        img {
          height: 50px;
        }
      }
    }
  }
  span {
    color: #ff6900;
    cursor: pointer;
  }
}
.fooder {
  width: 100%;
  text-align: center;
  img {
    width: 200px;
    margin-bottom: 20px;
  }
}
</style>
