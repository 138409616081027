<template>
  <div class="content_help">
    <div class="container">
      <el-tree
        :expand-on-click-node="false"
        :data="TreeData"
        accordion
        default-expand-all
        highlight-current
        :auto-expand-parent="false"
        node-key="id"
        ref="tree"
        :current-node-key="currentLivingId"
        :props="defaultProps"
        @node-click="handleNodeClick"
        :default-expanded-keys="treeExpandData"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span class="helpItem">
            {{ test(node, data) }}
            <el-image
              v-if="data.picture"
              :class="
                data.picture == '04'
                  ? 'dx'
                  : '' || data.picture == '05'
                  ? 'tx'
                  : '' || data.picture == '06'
                  ? 'tz'
                  : '' || data.picture == '07'
                  ? 'tz'
                  : ''
              "
              :src="
                require(`../../assets/images/join_about/${data.picture}.png`)
              "
            ></el-image>
            <span v-if="data.id == '1' || data.id == '2'" class="st">
              {{ data.title }}</span
            >
            <span v-else class="ts"> {{ data.title }}</span>
          </span>
        </span>
      </el-tree>
    </div>
    <div class="helpItemcontent">
      <div
        class="helpItemcontent—left"
        v-html="helpItemconhtml"
        :class="helpItemconhtml ? 'sb' : ''"
      ></div>
    </div>
  </div>
</template>

<script>
// import helpItemcontent from "components/help_center/helpItemcontent.vue";
import { getTitleList, getStepsContentById } from "api/header.js";
export default {
  // components: {
  //   helpItemcontent
  // },
  data() {
    return {
      // 父级ID
      parentid: -1,
      helpItemconhtml: "",
      helpId: this.$parent.showSearchId,
      // 默认展开的节点
      treeExpandData: [],
      //   数据
      TreeData: [],
      currentLivingId: this.$parent.showSearchId,
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  mounted() {
    this.getTitleList();
    this.getStepsContentById();
  },

  methods: {
    // 获取侧边栏
    getTitleList() {
      getTitleList().then((data) => {
        data.data.forEach((item) => {
          if (item.title == "热点文章") {
            item.picture = "04";
          } else if (item.title == "成语使用手册") {
            item.picture = "01";
          } else if (item.title == "公司及产品简介") {
            item.picture = "05";
          } else if (item.title == "PLUS卡使用指引") {
            item.picture = "06";
          } else if (item.title == "小课堂") {
            item.picture = "07";
          }
        });
        this.TreeData = data.data;
      });
    },
    getStepsContentById() {
      getStepsContentById(this.helpId).then((res) => {
        this.parentid = res.data.parentid;
        this.helpItemconhtml = res.data.stepsContent;
      });
    },
    //   节点被点击是回调
    handleNodeClick(data) {
      this.$refs.tree.setCurrentKey(data.id);
      // this.currentLivingId = data.id;
      this.helpId = data.id;
      this.getStepsContentById();
      // setTimeout(() => {
      //   this.$refs.helpItemcon.getStepsContentById();
      // }, 200);
    },
    test(node, data) {},
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  min-height: 200px;
  width: 320px;
  border-right: 1px solid #ccc;
  .helpItem {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-tree {
    min-width: 200px;
    max-width: 350px;
    margin-right: 30px;
    .el-tree-node {
      margin-bottom: 25px;
    }

    ::v-deep .el-tree-node.is-focusable {
      .el-tree-node__children {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;

        // margin-left: 14px;
      }
      .el-tree-node__content {
        margin-bottom: 11px;
        border-left: 4px solid #fff;
        // padding-left: 18px !important;
        // box-sizing: border-box;
        &:hover {
          background: #f5f6f7;
          border-left: 4px solid #ff6900;
          font-size: 16px;
          color: #000;
        }
      }

      .st {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
      }
      .ts {
        padding-left: 3px;
        // width: 200px;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }
    }
  }
  .el-image {
    width: 30px;
    height: 30px;
    vertical-align: middle;
  }
  .dx {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    ::v-deep .el-image__inner {
      width: 15px !important;
      height: 18px !important;
      display: block;
      margin: 6px auto;
    }
  }
  .tx {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    ::v-deep .el-image__inner {
      width: 20px !important;
      height: 22px !important;
      display: block;
      margin: 4px auto;
    }
  }
  .tz {
    width: 34px;
    height: 33px;
    vertical-align: middle;
    ::v-deep .el-image__inner {
      width: 34px !important;
      height: 33px !important;
      display: block;
      margin: 4px auto;
    }
  }
}
.helpItemcontent {
  box-sizing: border-box;
  padding: 0 40px;
  width: 100%;
  min-height: 80vh;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  .helpItemcontent—left {
    line-height: 32px !important;
  }
}
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: #f5f6f7;
  border-left: 4px solid #ff6900 !important;
  font-size: 16px;
  color: #000;
}
</style>
<style>
/*滚动条bai整体*/
::-webkit-scrollbar {
  width: 2px; /*滚动条宽度*/
}
::-webkit-scrollbar-track {
  background-color: #fff; /*滑道全部*/
}
::-webkit-scrollbar-track-piece {
  background-color: #fff; /*滑道*/
}
::-webkit-scrollbar-thumb {
  background-color: #fafafa; /*滑动条表面*/
  border: none; /*滑动条边框*/
}
</style>
