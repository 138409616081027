var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content_help"},[_c('div',{staticClass:"container"},[_c('el-tree',{ref:"tree",attrs:{"expand-on-click-node":false,"data":_vm.TreeData,"accordion":"","default-expand-all":"","highlight-current":"","auto-expand-parent":false,"node-key":"id","current-node-key":_vm.currentLivingId,"props":_vm.defaultProps,"default-expanded-keys":_vm.treeExpandData},on:{"node-click":_vm.handleNodeClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('span',{staticClass:"helpItem"},[_vm._v(" "+_vm._s(_vm.test(node, data))+" "),(data.picture)?_c('el-image',{class:data.picture == '04'
                ? 'dx'
                : '' || data.picture == '05'
                ? 'tx'
                : '' || data.picture == '06'
                ? 'tz'
                : '' || data.picture == '07'
                ? 'tz'
                : '',attrs:{"src":require(("../../assets/images/join_about/" + (data.picture) + ".png"))}}):_vm._e(),(data.id == '1' || data.id == '2')?_c('span',{staticClass:"st"},[_vm._v(" "+_vm._s(data.title))]):_c('span',{staticClass:"ts"},[_vm._v(" "+_vm._s(data.title))])],1)])}}])})],1),_c('div',{staticClass:"helpItemcontent"},[_c('div',{staticClass:"helpItemcontent—left",class:_vm.helpItemconhtml ? 'sb' : '',domProps:{"innerHTML":_vm._s(_vm.helpItemconhtml)}})])])}
var staticRenderFns = []

export { render, staticRenderFns }