<template>
  <div class="help_center">
    <div class="header-center">
      <!-- <h1>帮助文档</h1> -->
      <div class="search-icon">
        <!-- <i class="el-icon-search"></i> -->
        <!-- <el-input
          v-model="input_value"
          placeholder="请输入问题关键词"
          @change="search_value"
        ></el-input> -->
        <input
          type="text"
          v-model="input_value"
          placeholder="请输入问题关键词"
          @change="search_value"
        />
        <div class="btn" @click="search_value">
          <img :src="require('assets/images/included_search.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="sidebar-item" v-if="showSearch">
      <!-- 侧边栏 -->
      <HelpcenterItem ref="helpcenterItem" />
      <div v-if="urlId" style="width: 100%; text-align: center">
        <!-- 现在去<span style="color: #ff6900">注册</span> -->
      </div>
    </div>
    <div class="sidebar" v-else>
      <!-- 搜索 -->
      <SearchCriteria ref="SearchCriteriaref" />
    </div>
    <!-- <div class="share" v-if="showSearch">
      <ul>
        <li
          v-for="item in list"
          :key="item.id"
          @click="share(item.id, $event)"
          @mouseover="immigration(item.id)"
          @mouseleave="undock"
        >
          <div class="vx" v-if="item.id == 3 && showVx">
            <img src="../../assets/images/join_about/16.png" alt="" />
          </div>
          <div v-if="item.id == 2 && showPoneh" class="poneh">13828584514</div>
          <img :src="item.listShow ? item.img : item.img1" alt="" />
        </li>
      </ul>
    </div> -->
    <!-- 链接二维码弹窗 -->
    <el-dialog
      title="二维码"
      :close-on-click-modal="false"
      :visible.sync="QrCodeVisible"
      @close="handleCloseQrcode"
      class="qrcode"
    >
      <div class="content">
        <div class="notes">分享二维码</div>
        <div class="imgBox">
          <div ref="qrcode_box"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QrCode from "qrcodejs2";
import HelpcenterItem from "components/help_center/helpcenterItem.vue";
import SearchCriteria from "components/help_center/searchCriteria.vue";
export default {
  components: {
    HelpcenterItem,
    SearchCriteria,
  },
  data() {
    return {
      input_value: "",
      showSearch: true,
      showSearchId: 2,

      QrCodeVisible: false,
      urlId: false,
      showVx: false,
      showPoneh: false,

      list: [
        {
          id: 1,
          img: require("assets/images/join_about/12.png"),
          img1: require("assets/images/join_about/08.png"),
          listShow: true,
        },
        {
          id: 2,
          listShow: true,
          img: require("assets/images/join_about/09.png"),
          img1: require("assets/images/join_about/15.png"),
        },
        {
          id: 3,
          listShow: true,
          img: require("assets/images/join_about/10.png"),
          img1: require("assets/images/join_about/13.png"),
        },
        {
          id: 4,
          listShow: true,
          img: require("assets/images/join_about/14.png"),
          img1: require("assets/images/join_about/11.png"),
        },
      ],
    };
  },
  created() {
    // let url = "";
    // // url = "http://localhost:8081/help_center?id=81";
    // let Id = +url.split("=")[1];
    // if (Id) {
    //   this.urlId = true;
    //   this.showSearchId = Id;
    // }
  },

  methods: {
    backTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },

    // 鼠标移入
    immigration(id) {
      this.list.forEach((item) => {
        if (item.id == id) {
          item.listShow = false;
        }
      });
      if (id == 3) {
        this.showVx = true;
      } else if (id == 2) {
        this.showPoneh = true;
      }
    },
    // 鼠标移出
    undock() {
      this.list.forEach((item) => {
        item.listShow = true;
      });
      this.showVx = false;
      this.showPoneh = false;
    },
    // 关闭去除二维码
    handleCloseQrcode() {
      this.$refs.qrcode_box.innerHTML = "";
    },
    // 分享
    share(id, e) {
      if (id == 1) {
        if (
          this.$refs.helpcenterItem.helpId == 3 ||
          this.$refs.helpcenterItem.parentid == 3
        ) {
          this.QrCodeVisible = true;
          this.$nextTick(() => {
            this.crateQrcode(this.$refs.helpcenterItem.helpId, 1);
          });
        } else {
          this.QrCodeVisible = true;
          this.$nextTick(() => {
            this.crateQrcode(this.$refs.helpcenterItem.helpId);
          });
        }
      } else if (id == 4) {
        this.backTop();
      }
    },
    // 生成二维码
    crateQrcode(id, code) {
      let url = "";
      if (code == 1) {
        url = "https://mall.writemall.com/helpdemo?id=3";
      } else {
        url = `https://mall.writemall.com/openItem?id=${id}`;
      }
      this.qr = new QrCode(this.$refs.qrcode_box, {
        text: url,
        width: 128,
        height: 128,
        correctLevel: 3,
        colorDark: "#000000",
        colorLight: "#ffffff",
      });
    },
    search_value() {
      this.showSearch = false;
      setTimeout(() => {
        this.$refs.SearchCriteriaref.getStepsContentByTitle();
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.help_center {
  width: 100%;
  position: absolute;
  left: 0;
  // #F5F5F2
  background: #f5f5f2;
  // margin-top: -30px;
  top: 0;
  .header-center {
    width: 100%;
    height: 395px;
    text-align: center;
    overflow: hidden;
    padding: 175px 32.8% 138px;
    box-sizing: border-box;
    background: url("../../assets/images/help_bg.png") no-repeat;
    background-size: 100% 100%;
    .search-icon {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 39px;
      .btn {
        width: 62px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ff6500;
        border-radius: 0px 5px 5px 0px;
        cursor: pointer;
      }
      input {
        // width: 100%;
        flex: 1;
        height: 42px;
        border-radius: 0;
        background: #fff;
        // border-radius: 27px;
        padding-left: 20px;
      }
    }
    h1 {
      font-size: 50px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ff6900;
    }
    .el-icon-search {
      position: absolute;
      left: 20px;
      bottom: 17px;
      font-size: 20px;
      color: #9ba2ae;
      z-index: 1;
    }
  }
  .sidebar-item {
    width: 80%;
    padding-top: 30px;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: -30px;
    box-sizing: border-box;
    background: #fff;
    .content_help {
      width: 100%;
      display: flex;
    }
  }
  .sidebar {
    width: 100%;
    padding: 59px 27% 77px;
    box-sizing: border-box;
  }
  .qrcode {
    ::v-deep .el-dialog {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        padding: 65px 20px;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .notes {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }
          .imgBox {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
.share {
  position: fixed;
  cursor: pointer;
  right: 0px;
  display: flex;
  bottom: 35px;
  li {
    position: relative;
    .vx {
      position: absolute;
      right: 100px;
      bottom: -23px;
      img {
        width: 118px;
        height: 118px;
      }
    }
    .poneh {
      position: absolute;
      right: 100px;
      bottom: 0px;
      width: 213px;
      height: 70px;
      background: #ffffff;
      box-shadow: 0px 0px 13px 0px rgba(181, 182, 186, 0.4);
      border-radius: 5px;
      text-align: center;
      line-height: 70px;
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ff6900;
    }
  }
  img {
    width: 72px;
    height: 72px;
  }
}
</style>
